import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Spacer from "../../components/Spacer";
import "../../styles/casestudy.css";

const PrismicPage = (props) => {
  const projectimages = props.data.prismicPortfoliopage.data.body;
  // console.log(projectimages);
  // console.log("Props from prismic")
  // console.log(props.data.prismicPortfoliopage.data)

  const header_image = props.data.prismicPortfoliopage.data.header_image;

  return (
    <Layout pageTitle={props.data.prismicPortfoliopage.data.project_name.text}>
      <div className="mainpage">
        <GatsbyImage
          image={header_image.gatsbyImageData}
          alt={header_image.alt ? header_image.alt : "Case Study Image"}
          className="image fullwidth mobilecropped casestudyimage"
        />

        <Spacer classes="h10"></Spacer>
        <h1 className="casestudyheader">
          {props.data.prismicPortfoliopage.data.project_name.text}
        </h1>

        <Spacer classes="h30"></Spacer>

        <div className="casestudysection">
          {projectimages.map((item, i) => (
            <div key={item.id} className="projectimage">
              <GatsbyImage
                image={item.primary.project_image.gatsbyImageData}
                alt={
                  item.primary.project_image.alt
                    ? item.primary.project_image.alt
                    : "Portfolio"
                }
                className="image"
              />
            </div>
          ))}
        </div>

        <Spacer classes="h19"></Spacer>

        <p className="homebelief text casestudybelief">
          {props.data.prismicPortfoliopage.data.project_description.text}
        </p>

        <Spacer classes="h50"></Spacer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Portfoliopagequery2($id: String) {
    prismicPortfoliopage(id: { eq: $id }) {
      uid
      data {
        header_image {
          gatsbyImageData
          alt
        }
        body {
          ... on PrismicPortfoliopageDataBodyImages {
            id
            primary {
              project_image {
                gatsbyImageData
              }
            }
          }
        }
        project_name {
          html
          text
        }
        project_description {
          html
          text
        }
      }
    }
  }
`;

export const Head = () => <Seo title="Case Study" />;

export default PrismicPage;
